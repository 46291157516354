import { AUDIT_LOGS } from '../actions';

const defState = {
  auditLogs: [],
  showAuditLogsSuccessModal: false,
  showAuditLogsFailModal: false,
  pendingLogs: false,
  pendingMassDownload: false
};

export function fetchPrepareDownload(
  pCode, { url, privacyModes, applyToSubDomains, propertyType, packageId, siteHasAnalytics, startDate, endDate }
) {
  let privacyModeString = '';
  const isGDPR = privacyModes.includes('GDPR');
  const ID = propertyType === 'SITE' ? url : packageId;

  if (isGDPR && privacyModes.includes('USP')) {
    privacyModeString = 'full';
  } else if (isGDPR) {
    privacyModeString = 'gdpr';
  } else {
    privacyModeString = 'usp';
  }

  return {
    type: AUDIT_LOGS.PREPARE_DOWNLOAD.FETCH,
    payload: {
      siteUrl: ID,
      pCode,
      privacyMode: privacyModeString,
      subDomains: applyToSubDomains,
      propertyType,
      siteHasAnalytics,
      startDate,
      endDate
    }
  };
}

export function fetchDownload({ domain, uuid, logId, auditLogs }) {
  return {
    type: AUDIT_LOGS.DOWNLOAD.FETCH,
    payload: { domain, uuid, logId, auditLogs }
  };
}

export function fetchStatus(pCode) {
  return {
    type: AUDIT_LOGS.STATUS.FETCH,
    payload: { pCode }
  };
}

export function fetchDownloadAll(auditLogs, pCode) {
  return {
    type: AUDIT_LOGS.DOWNLOAD_ALL.FETCH,
    payload: { auditLogs, pCode }
  };
}

export function toggleFailedModal() {
  return { type: AUDIT_LOGS.STATUS.TOGGLE_FAILED_MODAL };
}

export function toggleSuccessModal() {
  return { type: AUDIT_LOGS.STATUS.TOGGLE_SUCCESS_MODAL };
}

export function hideAuditModals(isErrorModal = false, logs = null, pCode) {
  return { type: AUDIT_LOGS.STATUS.HIDE_MODALS, payload: { isErrorModal, logs, pCode } };
}

function auditLogsReducer(state = defState, action) {
  switch (action.type) {
    case AUDIT_LOGS.STATUS.TOGGLE_SUCCESS_MODAL: {
      return { ...state, showAuditLogsSuccessModal: true };
    }
    case AUDIT_LOGS.STATUS.TOGGLE_FAILED_MODAL: {
      return { ...state, showAuditLogsFailModal: true };
    }
    case AUDIT_LOGS.STATUS.HIDE_MODALS: {
      return { ...state, showAuditLogsFailModal: false, showAuditLogsSuccessModal: false };
    }
    case AUDIT_LOGS.STATUS.FULFILLED: {
      return { ...state, auditLogs: action.payload.data, pendingLogs: action.payload.pendingLogs };
    }
    case AUDIT_LOGS.DOWNLOAD.PENDING: {
      const { auditLogs } = state;
      const { payload: { logId } } = action;
      return {
        ...state,
        auditLogs: auditLogs.map(log => ({
          ...log,
          isDownloading: log.id === logId
        })),
      };
    }
    case AUDIT_LOGS.DOWNLOAD_ALL.PENDING: {
      return { ...state, pendingMassDownload: true };
    }
    case AUDIT_LOGS.DOWNLOAD_ALL.FULFILLED: {
      return { ...state, pendingMassDownload: false, showAuditLogsFailModal: false, showAuditLogsSuccessModal: false };
    }
    case AUDIT_LOGS.STATUS.REMOVE_OLDER_LOGS: {
      const { auditLogs } = state;
      const { payload } = action;
      return {
        ...state,
        auditLogs: auditLogs.map(log => ({
          ...log,
          isDownloading: payload.includes(log.id) ? false : !!log.isDownloading,
          isDownloaded: payload.includes(log.id) ? true : !!log.isDownloaded
        })),
      };
    }
    default: {
      return state;
    }
  }
}

export default auditLogsReducer;
