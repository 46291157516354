import { buildError } from '../../utils/reducer';
import { SITES } from '../actions';

const defState = {
  current: {},
  error: {
    status: 200,
    message: ''
  },
  list: [],
  pending: false,
  fetched: false,
  useFilterData: false,
  ampConfig: '',
  supportCustomizedVendors: {}
};

export function useFilterData() {
  return { type: SITES.INIT_DATA.USEFILTERDATA };
}

export function getSitesInitData(pcode, type, isPremium = true, cmpVersions = true) {
  return { type: SITES.INIT_DATA.FETCH, payload: { pcode, type, isPremium, cmpVersions } };
}

export function createSite(siteData, history) {
  return {
    type: SITES.CREATE.FETCH,
    payload: { siteData, history }
  };
}

export function editSite(siteId, siteData, history, urlChanges) {
  return {
    type: SITES.EDIT.FETCH,
    payload: { siteId, siteData, history, urlChanges }
  };
}

export function deleteSite(siteId, pCode, siteUrl) {
  return {
    type: SITES.DELETE.FETCH,
    payload: { siteId, pCode, siteUrl }
  };
}

export function getById(id, pCode) {
  return {
    type: SITES.GET_BY_ID.FETCH,
    payload: { id, pCode }
  };
}

export function clearCurrent() {
  return { type: SITES.GET_BY_ID.CLEANED };
}

export function permissionsGrant() {
  return { type: SITES.PERMISSIONS.GRANT };
}

export function fetchAmpTag(siteId) {
  return {
    type: SITES.GET_AMP_TAG.FETCH,
    payload: siteId
  };
}

export function clearAmpTag() {
  return { type: SITES.GET_AMP_TAG.CLEANED };
}

export function fetchSupportedCustomizedVendors(pCode) {
  return {
    type: SITES.GET_SUPPORT_CUSTOM_VENDORS.FETCH,
    payload: pCode
  };
}

function sitesReducer(state = defState, action) {
  switch (action.type) {
    // Permissions
    case SITES.PERMISSIONS.GRANT: {
      return { ...state, error: { status: 200, message: '' }, fetched: true };
    }
    // Pending
    case SITES.GET_ALL.PENDING:
    case SITES.GET_BY_ID.PENDING:
    case SITES.CREATE.PENDING:
    case SITES.EDIT.PENDING:
    case SITES.DELETE.PENDING:
    case SITES.GENERATE_NON_IAB_LIST.PENDING:
    case SITES.GET_AMP_TAG.PENDING:
    case SITES.GET_SUPPORT_CUSTOM_VENDORS.PENDING: {
      return { ...state, pending: true, fetched: false, error: { status: 200, message: '' } };
    }
    // Fulfilled
    case SITES.GET_ALL.FULFILLED: {
      return {
        ...state,
        list: action.payload.sites,
        pending: false,
        fetched: true,
        useFilterData: false,
      };
    }
    case SITES.GET_BY_ID.FULFILLED: {
      return { ...state, current: action.payload, pending: false, fetched: true, useFilterData: false };
    }
    case SITES.CREATE.FULFILLED: {
      return {
        ...state,
        list: [...state.list, action.payload],
        current: { finished: true },
        pending: false,
        fetched: true,
        useFilterData: false
      };
    }
    case SITES.DELETE.FULFILLED: {
      const newList = state.list.filter(item => item.siteId !== parseInt(action.payload.id, 10));
      return {
        ...state,
        list: newList,
        pending: false,
        fetched: true,
        useFilterData: false,
        current: { finished: true }
      };
    }
    case SITES.EDIT.FULFILLED: {
      const list = [...state.list].filter(item => item.siteId !== action.payload.id);
      list.unshift(action.payload.data);
      return {
        ...state,
        pending: false,
        list,
        current: {
          ...action.payload.data,
          finished: true
        },
        fetched: true,
        useFilterData: false
      };
    }
    case SITES.GET_AMP_TAG.FULFILLED: {
      return { ...state, ampConfig: action.payload, pending: false, fetched: true, useFilterData: false };
    }
    case SITES.GET_SUPPORT_CUSTOM_VENDORS.FULFILLED: {
      return {
        ...state,
        pending: false,
        fetched: true,
        useFilterData: false,
        supportCustomizedVendors: action.payload
      };
    }
    // Rejected
    case SITES.EDIT.REJECTED:
    case SITES.CREATE.REJECTED:
    case SITES.DELETE.REJECTED: {
      return { ...state, pending: false, current: { finished: true }, error: buildError(action.payload) };
    }
    case SITES.GET_BY_ID.REJECTED:
    case SITES.GET_ALL.REJECTED:
    case SITES.GET_AMP_TAG.REJECTED:
    case SITES.GET_SUPPORT_CUSTOM_VENDORS.REJECTED: {
      return { ...state, pending: false, error: buildError(action.payload) };
    }
    // Others
    case SITES.GET_BY_ID.CLEANED: {
      return { ...state, current: {} };
    }
    case SITES.INIT_DATA.USEFILTERDATA: {
      return { ...state, useFilterData: true };
    }
    case SITES.GET_AMP_TAG.CLEANED: {
      return { ...state, ampConfig: '' };
    }
    default: {
      return state;
    }
  }
}

export default sitesReducer;
