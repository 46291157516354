/* eslint-disable max-len */
// @flow
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Menu from 'antd/es/menu';
import Popover from 'antd/es/popover';
import Space from 'antd/es/space';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import { faFilter } from '@fortawesome/fontawesome-pro-solid';
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderExpandIcon from '../../../../../../components/ui/table/tableExpandIcon';
import { LEGAL_BASIS_LIST, ENVIRONMENT_LIST, TYPE_OF_SERVICE, INTERNATIONAL_TRANSFER } from '../../../../../../utils/constants';
import VendorInformation from '../vendor-information';
import Styles from './table.module.scss';

type Props = {
  data: Array;
  handleSelectedVendors: Function;
  isGVLVersion3: boolean;
}

const CONSENT = 'Consent';
const LEGITIMATE_INTEREST = 'Legitimate Interest';
const FLEXIBLE = 'Flexible';
const MULTI = 'Multi';

const VendorsTable = ({ data, handleSelectedVendors, isGVLVersion3 }: Props) => {
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [filterVendors, setFilterVendors] = useState({
    legalBasis: [],
    environment: [],
    serviceTypes: [],
    internationalTransfers: []
  });
  const [filterData, setFilterData] = useState(data);
  const [allVendorsSelected, setAllVendorsSelected] = useState(false);
  const [displayData, setDisplayData] = useState(data);
  const [pageSize, setPageSize] = useState(70);

  const filterLegalBasis = (checkedFilters, currentFilterData) => {
    const filteredData = currentFilterData.filter((vendor) => {
      let includes = [];
      if (vendor.legalBasis) {
        includes = checkedFilters.legalBasis.filter(env => env === vendor.legalBasis);
      }
      return includes.length > 0;
    });
    return filteredData;
  };

  const filterEnvironment = (checkedFilters, currentFilterData) => {
    const filteredData = currentFilterData.filter((vendor) => {
      let includes = [];
      if (vendor.environments && vendor.environments.length) {
        includes = vendor.environments.filter(env => checkedFilters.environment.includes(env));
      }
      return includes.length > 0;
    });
    return filteredData;
  };

  const filterServiceType = (checkedFilters, currentFilterData) => {
    const filteredData = currentFilterData.filter((vendor) => {
      let includes = [];
      if (vendor.serviceTypes && vendor.serviceTypes.length) {
        includes = vendor.serviceTypes.filter(service => checkedFilters.serviceTypes.includes(service));
      }
      return includes.length > 0;
    });
    return filteredData;
  };

  const filterInternationalT = (checkedFilters, currentFilterData) => {
    const it = checkedFilters.internationalTransfers;
    const filteredData = currentFilterData.filter(
      vendor => (it.includes('YES') && vendor.internationalTransfers) || (it.includes('NO') && !vendor.internationalTransfers)
    );
    return filteredData;
  };

  const applyFilters = (filterObject) => {
    let filteredData = displayData;
    if (filterObject.legalBasis.length) {
      filteredData = filterLegalBasis(filterObject, filteredData);
    }
    if (filterObject.environment.length) {
      filteredData = filterEnvironment(filterObject, filteredData);
    }
    if (filterObject.serviceTypes.length) {
      filteredData = filterServiceType(filterObject, filteredData);
    }
    if (filterObject.internationalTransfers.length) {
      filteredData = filterInternationalT(filterObject, filteredData);
    }
    setFilterData(filteredData);
  };

  useEffect(() => setDisplayData(data), [data]);

  useEffect(() => {
    if (!filterData.length) {
      setFilterData(displayData);
    } else {
      applyFilters(filterVendors);
    }
  }, [displayData]);

  const clearFilter = (filter) => {
    const filterObject = {
      ...filterVendors,
      [filter]: []
    };
    applyFilters(filterObject);
    setFilterVendors(filterObject);
  };

  if (displayData && displayData.length && !displayData[0].legalBasis) {
    const legalFilteredData = [];

    displayData.forEach((vendor) => {
      const { legIntPurposes, purposes, flexiblePurposes } = vendor;
      let legalBasis = CONSENT;

      if (flexiblePurposes.length) {
        legalBasis = FLEXIBLE;
      } else if (!legIntPurposes.length && purposes.length && !flexiblePurposes.length) {
        legalBasis = CONSENT;
      } if (legIntPurposes.length && purposes.length && !flexiblePurposes.length) {
        legalBasis = MULTI;
      } else if (legIntPurposes.length && !purposes.length && !flexiblePurposes.length) {
        legalBasis = LEGITIMATE_INTEREST;
      }

      legalFilteredData.push({ ...vendor, legalBasis });
    });
    setDisplayData(legalFilteredData);
  }

  const handleVendorChange = (id) => {
    let checked = [...selectedVendors];

    if (!checked.includes(id)) {
      checked.push(id);
    } else {
      checked = checked.filter(vendorId => vendorId !== id);
    }

    setSelectedVendors(checked);
    handleSelectedVendors(checked);
    setAllVendorsSelected(data.length === checked.length);
  };

  const handleAllVendorsChange = () => {
    const vendorsSelected = filterData.length > 0 ? filterData : data;
    const checked = [];

    if (!allVendorsSelected) {
      vendorsSelected.forEach((vendor) => {
        checked.push(vendor.id);
      });
    }

    setSelectedVendors(checked);
    handleSelectedVendors(checked);
    setAllVendorsSelected(checked.length === vendorsSelected.length);
  };

  const handleListCheck = (checkSelected, listValue) => {
    let checked = [...filterVendors[listValue]];

    if (!checked.includes(checkSelected.name)) {
      checked.push(checkSelected.name);
    } else {
      checked = checked.filter(selected => selected !== checkSelected.name);
    }

    const selectedValue = {
      ...filterVendors,
      [listValue]: checked
    };

    setFilterVendors(selectedValue);
    applyFilters(selectedValue);
  };

  const getColumnFilter = (listValue) => {
    const includesValue = filterValue => filterVendors[listValue] && filterVendors[listValue].includes(filterValue);

    let list;
    switch (listValue) {
      case 'legalBasis':
        list = LEGAL_BASIS_LIST;
        break;
      case 'environment':
        list = ENVIRONMENT_LIST;
        break;
      case 'serviceTypes':
        list = TYPE_OF_SERVICE;
        break;
      case 'internationalTransfers':
        list = INTERNATIONAL_TRANSFER;
        break;
      default:
        list = LEGAL_BASIS_LIST;
        break;
    }

    return (
      <span className={Styles.legalFilter}>
        <Menu>
          {list.map(filter => (
            <Menu.Item key={filter.name} className={Styles.filterItem}>
              <Checkbox
                checked={includesValue(filter.name)}
                className={Styles.checkBoxSizeLabel}
                onChange={() => handleListCheck(filter, listValue)}
              >
                {filter.name}
              </Checkbox>
              <Tooltip title={filter.description}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ verticalAlign: '-0.8em' }}
                  size="2x"
                />
              </Tooltip>
            </Menu.Item>
          ))
          }
        </Menu>
      </span>
    );
  };

  const renderName = ({ name, id }) => (
    <Checkbox
      checked={selectedVendors.includes(id)}
      className={Styles.checkBoxSizeLabel}
      onChange={() => handleVendorChange(id)}
    >
      <span className={Styles.textBlocked}>{name}</span>
    </Checkbox>
  );

  const paginationHandler = (_, noOfRows) => setPageSize(noOfRows);

  const renderStatusOption = blocked => (blocked
    ? <Tag color="error">Blocked</Tag> : <Tag color="success"> Whitelisted </Tag>);

  const getVendorsInfo = dataList => dataList.map(vendor => ({
    vendorName: {
      name: vendor.name,
      id: vendor.id,
      blocked: vendor.blocked
    },
    key: vendor.id,
    legalBasis: {
      legalBasis: vendor.legalBasis,
      blocked: vendor.blocked
    },
    environment: vendor.environments,
    typeOfService: vendor.serviceTypes,
    internationalTransfers: vendor.internationalTransfers,
    deviceStorageDisclosureUrl: vendor.deviceStorageDisclosureUrl,
    statusOption: vendor.blocked,
    isExpandable: !!vendor.children,
    vendor
  }));

  const columns = [
    {
      title: (
        <Space>
          <Checkbox checked={allVendorsSelected} onChange={handleAllVendorsChange} />
          Vendor Name
        </Space>
      ),
      dataIndex: 'vendorName',
      render: renderName,
      key: 'VendorName',
      width: '25%'
    },
    {
      title: (
        <Space>
          Legal Basis
          <Popover
            placement="bottom"
            content={getColumnFilter('legalBasis')}
            className={Styles.popover}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Popover>
          {!!filterVendors.legalBasis.length
            && (
              <Button
                type="secondary"
                size="small"
                onClick={() => clearFilter('legalBasis')}
              >
                CLEAR
              </Button>
            )}
        </Space>
      ),
      render: (legalObject) => {
        const { legalBasis, blocked } = legalObject;
        return (
          <div className={Styles.legalBasis}>
            <span className={classNames({ [Styles.textBlocked]: blocked })}>{legalBasis}</span>
          </div>
        );
      },
      dataIndex: 'legalBasis',
      key: 'legalBasis',
      width: '10%'
    },
    {
      title: (
        <Space className={Styles.header}>
          Environment
          <Popover
            placement="bottom"
            content={getColumnFilter('environment')}
            className={Styles.popover}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Popover>
          {!!filterVendors.environment.length
            && (
              <Button
                type="secondary"
                size="small"
                onClick={() => clearFilter('environment')}
              >
                CLEAR
              </Button>
            )}
        </Space>
      ),
      dataIndex: 'environment',
      render: environments => (environments ? environments.join(', ') : ''),
      key: 'environment',
      width: '20%'
    },
    {
      title: (
        <Space>
          Type of Service
          <Popover
            placement="bottom"
            content={getColumnFilter('serviceTypes')}
            className={Styles.popover}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Popover>
          {!!filterVendors.serviceTypes.length
            && (
              <Button
                type="secondary"
                size="small"
                onClick={() => clearFilter('serviceTypes')}
              >
                CLEAR
              </Button>
            )}
        </Space>
      ),
      dataIndex: 'typeOfService',
      render: services => (services ? services.join(', ') : ''),
      key: 'typeOfService',
      width: '20%'
    },
    {
      title: (
        <Space>
          International Transfer
          <Popover
            placement="bottom"
            content={getColumnFilter('internationalTransfers')}
            className={Styles.popover}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Popover>
          {!!filterVendors.internationalTransfers.length
            && (
              <Button
                type="secondary"
                size="small"
                onClick={() => clearFilter('internationalTransfers')}
              >
                CLEAR
              </Button>
            )}
        </Space>
      ),
      dataIndex: 'internationalTransfers',
      render: internationalTransfers => (internationalTransfers ? 'YES' : 'NO'),
      key: 'internationalTransfer',
      width: '10%'
    },
    {
      title: 'Status',
      dataIndex: 'statusOption',
      key: 'statusOption',
      render: renderStatusOption
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={{
        pageSize,
        position: ['bottomCenter'],
        onShowSizeChange: paginationHandler,
        hideOnSinglePage: true
      }}
      className={Styles.table}
      rowClassName={record => (record.vendor.blocked ? Styles.vendorBlocked : '')}
      expandable={{
        expandedRowRender: (record) => {
          const { vendor: { children, deviceStorageDisclosureUrl } } = record;
          if (children.length === 1) {
            return (
              <VendorInformation
                vendor={children[0]}
                deviceStorageDisclosureUrl={deviceStorageDisclosureUrl}
                policyUrl={children[0].policyUrl}
                isGVLVersion3={isGVLVersion3}
              />
            );
          }
          return '';
        },
        expandIcon: renderExpandIcon(),
        rowExpandable: record => record.isExpandable
      }}
      dataSource={getVendorsInfo(filterData)}
    />
  );
};

export default VendorsTable;
