/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { requestAuditLogs, requestAuditLogsV2 } from '../../utils/requests';

export function requestPrepareDownload([siteUrl, pCode, , subDomains, propertyType, startDate, endDate]) {
  return requestAuditLogsV2
    .post(
      `/audit/zip/range/v2?domain=${siteUrl}&pcode=${pCode}&subDomains=${subDomains}&propertyType=${propertyType}
      &startDate=${startDate}&endDate=${endDate}`
    ).then(res => ({ res }))
    .catch(err => ({ err }));
}

export function requestDownload([id, url]) {
  return requestAuditLogs
    .get(`/download/zip/v2/${id}`)
    .then(res => ({ res, url }))
    .catch(err => ({ err }));
}

export function requestStatus(pCode) {
  return requestAuditLogsV2
    .get(`/audit/zip/pcode/${pCode}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
