// @flow
import React, { useState, useEffect, useRef } from 'react';
import Loader from '../../components/ui/loader';
import type { VendorsContainerProps } from '../../interfaces/vendors';
import AlertsContainer from '../../components/ui/alerts-container';
import ConfirmationModal from './components/modal';
import VendorsTableGVL from './components/vendors-table-v2';
import Toolbar from './components/toolbar';
import Styles from './vendors.module.scss';
import ContentHeader from '../../components/ui/content-header';
import GoogleVendors from './components/google';
import NonIAB from './components/non-iab';
import NoContent from '../../components/ui/no-content';
import ModalForm from './components/modal-form';
import formatInitData from './components/modal-form/formatData';
import { GVL_VERSION_2, GVL_VERSION_3 } from '../../utils/constants';

const VendorsContainer = ({ actions, sites, vendors, nonIabVendors, app }: VendorsContainerProps) => {
  const [dataControl, setDataControl] = useState({});
  const [filterDataV2, setFilterDataV2] = useState([]);
  const [filterDataV3, setFilterDataV3] = useState([]);
  const [nonIABFilterData, setNonIABFilterData] = useState([]);
  const [googleFilterData, setGoogleFilterData] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedGoogleVendors, setSelectedGoogleVendors] = useState([]);
  const [notResults, setNotResults] = useState(false);
  const [modal, setModal] = useState({ open: false, action: 'block' });
  const [currentTab, setCurrentTab] = useState('');
  const [formOpen, setFormOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});
  const [ModalHeader, setModalHeader] = useState('Add vendor');
  const [shouldCleanFilter, setShouldCleanFilter] = useState(false);
  const googleWhiteListRef = useRef();
  const [GVLVersion, setGVLVersion] = useState();

  // All V2  and tab__IAB references must be removed after v2 is deprecated
  useEffect(() => {
    if (currentTab === '') {
      setCurrentTab('tab__IAB'); // Replace with tab__IAB_2.2 after V2 is deprecated
    }
  }, []);
  useEffect(() => {
    if (currentTab === 'tab__IAB') {
      setGVLVersion(GVL_VERSION_2);
    } else if (currentTab === 'tab__IAB_2.2') {
      setGVLVersion(GVL_VERSION_3);
    }
  }, [currentTab]);

  useEffect(() => {
    actions.alerts.clear();
  }, []);

  useEffect(() => {
    const { data, pending } = GVLVersion === 3 ? vendors.v3 : vendors.v2;
    if (!pending && Object.keys(data).length > 0 && data.vendors.length > 0) {
      setDataControl(data);
    }

    if (Object.keys(nonIabVendors.current).length && !(nonIabVendors.pending)) {
      setFormOpen(true);
      setVendorInfo(nonIabVendors.current);
      setIsAdd(false);
      setModalHeader('Edit vendor');
    }
  }, [vendors, nonIabVendors]);

  useEffect(() => {
    actions.sites.fetchSupportedCustomizedVendors(app.pCode);

    if (!nonIabVendors.list.length) {
      actions.nonIabVendors.fetchAllNonIabVendors(app.pCode);
    }
    if (!Object.keys(vendors.v2.data).length) {
      actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_2);
    }
    if (!Object.keys(vendors.v3.data).length) {
      actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_3);
    }
  }, []);

  useEffect(() => {
    const { whitelistGoogleVendors } = GVLVersion === GVL_VERSION_3 ? vendors.v3.data : vendors.v2.data;
    const { googleList } = nonIabVendors;
    if (whitelistGoogleVendors && whitelistGoogleVendors.length && googleList.length
      && JSON.stringify(whitelistGoogleVendors) !== JSON.stringify(googleWhiteListRef.current)
    ) {
      let newSelectedGoogleVendors = [];
      if (JSON.stringify(whitelistGoogleVendors) === '[1]') {
        newSelectedGoogleVendors = googleList.map(row => parseInt(row.provider_id, 10));
      } else if (JSON.stringify(whitelistGoogleVendors) === '[-1]') {
        newSelectedGoogleVendors = [];
      } else if (
        whitelistGoogleVendors
        && whitelistGoogleVendors.length > 0
      ) {
        newSelectedGoogleVendors = [...whitelistGoogleVendors];
      }
      googleWhiteListRef.current = whitelistGoogleVendors;
      setSelectedGoogleVendors(newSelectedGoogleVendors);
    }
  }, [vendors.v2.data.whitelistGoogleVendors, vendors.v3.data.whitelistGoogleVendors, nonIabVendors.googleList]);

  useEffect(() => {
    if (currentTab === 'tab__IAB') {
      setGVLVersion(GVL_VERSION_2);
    } else if (currentTab === 'tab__IAB_2.2') {
      setGVLVersion(GVL_VERSION_3);
    }
  }, [currentTab]);
  const createAlertTypes = (action, supported) => {
    const copies = {
      block: {
        type: 'success',
        message: `You have successfully blocked these vendors on ${supported.length} sites`,
      },
      unblock: {
        type: 'success',
        message: 'You have successfully unblocked these vendors',
      }
    };
    return { ...copies[action] };
  };

  const handleSelectedVendors = (selected) => {
    setSelectedVendors(selected);
  };

  const handleModal = (open, action = 'block'): void => {
    setModal({ open, action });
  };

  const blockHandler = (action = 'block'): void => {
    const {
      v2: {
        data: {
          blockedVendors: blockedVendorsV2,
          vendorListVersion: vendorListVersionV2,
          pCode: pCodeV2,
          vendors: vendorsListV2
        }
      },
      v3: {
        data: {
          blockedVendors: blockedVendorsV3,
          vendorListVersion: vendorListVersionV3,
          pCode: pCodeV3,
          vendors: vendorsListV3
        }
      }
    } = vendors;

    const latestBlockedVendorsV3 = blockedVendorsV3.filter(blockedVendor => vendorsListV3
      .filter(item => item.id === blockedVendor));
    const latestBlockedVendorsV2 = blockedVendorsV2.filter(blockedVendor => vendorsListV2
      .filter(item => item.id === blockedVendor));

    const { supportCustomizedVendors: { supported } } = sites;
    const isGVLVersion3 = GVLVersion === GVL_VERSION_3;
    const currentBlockedVendors = isGVLVersion3 ? latestBlockedVendorsV3 : latestBlockedVendorsV2;
    let updateChoiceJs = false;

    const alert = createAlertTypes(action, supported);
    if (action === 'block') {
      if (!blockedVendorsV2.length || !blockedVendorsV3.length) {
        updateChoiceJs = true;
      }
      actions.vendors.toggleBlock(
        isGVLVersion3 ? pCodeV3 : pCodeV2,
        isGVLVersion3 ? vendorListVersionV3 : vendorListVersionV2,
        [...new Set([...currentBlockedVendors, ...selectedVendors])],
        alert,
        updateChoiceJs,
        GVLVersion
      );
    }
    if (action === 'unblock') {
      const blockedObject = isGVLVersion3 ? blockedVendorsV3 : blockedVendorsV2;

      const vendorsThatRemainLocked = isGVLVersion3
        ? blockedVendorsV3.filter(item => !selectedVendors.includes(item))
        : blockedVendorsV2.filter(item => !selectedVendors.includes(item));
      const toUnlock = isGVLVersion3
        ? blockedVendorsV3.filter(item => selectedVendors.includes(item))
        : blockedVendorsV2.filter(item => selectedVendors.includes(item));
      if (blockedObject.length && (blockedObject.length === toUnlock.length)) {
        updateChoiceJs = true;
      }
      actions.vendors.toggleBlock(
        isGVLVersion3 ? pCodeV3 : pCodeV2,
        isGVLVersion3 ? vendorListVersionV3 : vendorListVersionV2,
        vendorsThatRemainLocked,
        alert,
        updateChoiceJs,
        GVLVersion
      );
    }


    handleModal(false);
    setDataControl({});
    setSelectedVendors([]);
  };

  const filterFn = (filteredData: Array<Object>): void => {
    const hasResults = !filteredData.length;
    if (currentTab === 'tab__IAB') {
      setFilterDataV2(filteredData);
      setNonIABFilterData([]);
      setFilterDataV3([]);
    } else if (currentTab === 'tab__IAB_2.2') {
      setFilterDataV3(filteredData);
      setNonIABFilterData([]);
      setFilterDataV2([]);
    } else if (currentTab === 'tab__NonIAB') {
      setNonIABFilterData(filteredData);
      setFilterDataV2([]);
      setFilterDataV3([]);
    } else if (currentTab === 'tab__Google') {
      setGoogleFilterData(filteredData);
      setFilterDataV2([]);
      setFilterDataV3([]);
    }

    setShouldCleanFilter(false);
    setNotResults(hasResults);
  };

  const renderTable = () => {
    if (notResults) {
      return <NoContent description="No Results Found" />;
    }

    if (Object.keys(dataControl).length > 0 && dataControl.vendors.length > 0) {
      return (
        <VendorsTableGVL
          filteredDataV2={filterDataV2}
          filteredDataV3={filterDataV3}
          handleSelectedVendors={handleSelectedVendors}
          GVLVersion={GVLVersion}
        />
      );
    }

    return <Loader />;
  };

  const handleChangeGoogleList = (googleVendors) => {
    setSelectedGoogleVendors(googleVendors);
  };

  const renderGoogleTable = () => {
    if (notResults) {
      return <NoContent description="No Results Found" />;
    }

    return (
      <GoogleVendors
        data={googleFilterData.length ? googleFilterData : nonIabVendors.googleList}
        selectedGoogleVendors={selectedGoogleVendors}
        handleChangeGoogleList={handleChangeGoogleList}
      />
    );
  };

  const handleTabs = (tabId: string): void => {
    setSelectedVendors([]);
    setCurrentTab(tabId);
  };

  const AddVendor = () => {
    setFormOpen(true);
    setIsAdd(true);
    setVendorInfo({});
    setModalHeader('Add vendor');
  };

  const saveGooglelist = () => {
    let rowsToSave;
    if (nonIabVendors.googleList.length === selectedGoogleVendors.length) {
      rowsToSave = [1];
    } else if (selectedGoogleVendors.length === 0) {
      rowsToSave = [-1];
    } else {
      rowsToSave = selectedGoogleVendors;
    }
    actions.vendors.toggleGoogleWhiteList(app.pCode, rowsToSave);
    actions.vendors.toggleGoogleWhiteListV3(app.pCode, rowsToSave);
  };

  const toggleForm = () => {
    actions.nonIabVendors.clearCurrent();
    setFormOpen(false);
    setIsAdd(false);
    setVendorInfo({});
  };

  const onSubmit = (e, values) => {
    e.preventDefault();

    if (isAdd) {
      actions.nonIabVendors.fetchCreateNoIabVendor(values, app.pCode, nonIabVendors.list.length);
    } else {
      actions.nonIabVendors.editNonIabVendor(vendorInfo.nonIabId, values);
    }
    toggleForm();
  };

  const onEditNonIabVendor = (value) => {
    actions.nonIabVendors.getById(value.nonIabId);
  };

  let filterBaseData = null;

  if (currentTab === 'tab__IAB') {
    filterBaseData = vendors.v2.data.vendors;
  } else if (currentTab === 'tab__NonIAB') {
    filterBaseData = nonIabVendors.list.length && nonIabVendors.list;
  } else if (currentTab === 'tab__Google') {
    filterBaseData = nonIabVendors.googleList.length && nonIabVendors.googleList;
  } else if (currentTab === 'tab__IAB_2.2') {
    filterBaseData = vendors.v3.data.vendors;
  }

  const vendorsLength = (): string => {
    // Remove v2 after V2 is deprecated
    let v2Length = vendors.v2.data.vendors ? vendors.v2.data.vendors.length : '0';
    if (filterDataV2 && filterDataV2.length) {
      v2Length = filterDataV2.length;
    }
    let v3Length = vendors.v3.data.vendors ? vendors.v3.data.vendors.length : '0';
    if (filterDataV3 && filterDataV3.length) {
      v3Length = filterDataV3.length;
    }
    return currentTab === 'tab__IAB' ? v2Length : v3Length;
  };

  const googleVendorsLength = (): string => {
    let googleLength = nonIabVendors.googleList ? nonIabVendors.googleList.length : '0';
    if (googleFilterData && googleFilterData.length) {
      googleLength = googleFilterData.length;
    }
    return googleLength;
  };

  let vendorsQuantity = '0';

  if (currentTab === 'tab__IAB') {
    vendorsQuantity = vendorsLength();
  } else if (currentTab === 'tab__IAB_2.2') {
    vendorsQuantity = vendorsLength();
  } else if (currentTab === 'tab__Google') {
    vendorsQuantity = googleVendorsLength();
  } else if (currentTab === 'tab__NonIAB') {
    vendorsQuantity = nonIabVendors.list.length;
  }

  return (
    <>
      <section className={Styles.wrapper}>
        <p>Manage third-party vendors asking for consent across your sites.</p>
        <div className={Styles.alerts}>
          <AlertsContainer />
        </div>
        <section className={Styles.container}>
          <header className={Styles.header}>
            <Toolbar
              vendorsQuantity={vendorsQuantity}
              filter={{
                baseData: filterBaseData,
                filterBy: currentTab === 'tab__Google' ? ['provider_name'] : ['name'],
                onFilter: filterFn,
                shouldCleanFilter
              }}
              handleModal={handleModal}
              disabled={selectedVendors.length === 0}
              googleSaveDisable={vendors.googleVendorWhiteList.pending || vendors.v2.pending}
              onTabClick={handleTabs}
              onAddClick={AddVendor}
              onSaveGoogleList={saveGooglelist}
              pcode={app.pCode}
            />
          </header>
          <main className={Styles.content}>
            {currentTab === 'tab__IAB' && renderTable()}
            {currentTab === 'tab__IAB_2.2' && renderTable()}
            {currentTab === 'tab__NonIAB' && (
              <NonIAB
                onDelete={actions.nonIabVendors.deleteNoIABVendor}
                tableData={nonIABFilterData.length ? nonIABFilterData : nonIabVendors.list}
                onAddClick={AddVendor}
                onEdit={onEditNonIabVendor}
              />
            )}
            {currentTab === 'tab__Google' && renderGoogleTable()}
          </main>
        </section>
      </section>
      <ConfirmationModal
        open={modal.open}
        action={modal.action}
        onClose={() => handleModal(false)}
        blockHandler={blockHandler}
      />
      <ModalForm
        onSubmit={onSubmit}
        open={formOpen}
        toggleOpen={toggleForm}
        initData={formatInitData(app.accountId, app.pCode, vendorInfo)}
        header={ModalHeader}
        isAdd={isAdd}
      />
    </>
  );
};

export default VendorsContainer;
