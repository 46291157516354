import { call, put } from 'redux-saga/effects';
import { AUDIT_LOGS, ALERTS } from '../actions';
import { requestPrepareDownload, requestDownload, requestStatus } from '../requests/audit-logs';
import { downloadFile } from '../requests/utils';
import { ALERT_IDENTIFIERS } from '../../utils/constants';
import { getStorage, removeStorage, updateStorage } from '../../utils/localStorage';

function getStorageLogs() {
  const savedLogs = getStorage('qc-frontend-manager-audit-logs');
  return savedLogs ? JSON.parse(savedLogs) : [];
}

function removeStorageLogs() {
  removeStorage('qc-frontend-manager-audit-logs');
}

function updateStorageLogs(logs) {
  const savedLogs = getStorage('qc-frontend-manager-audit-logs');
  const newValue = savedLogs ? JSON.parse(savedLogs).concat(logs) : logs;
  updateStorage('qc-frontend-manager-audit-logs', newValue);
}

export function* fetchStatus({ payload }) {
  const { res } = yield call(requestStatus, payload.pCode);
  if (res) {
    if (res.data) {
      const savedLogs = getStorageLogs();
      if (res.data.length > 0) {
        const filteredLogs = res.data.filter(log => !savedLogs.some(sLogId => sLogId === log.id));
        if (filteredLogs.length > 0) {
          const errorLogs = filteredLogs.some(
            ({ queryState }) => queryState === 'FAILED' || queryState === 'CANCELLED'
          );
          const successLogs = filteredLogs.some(({ queryState }) => queryState === 'SUCCEEDED');
          const runningLogs = filteredLogs.some(({ queryState }) => queryState === 'RUNNING');
          if(runningLogs) {
            yield put({
              type: ALERTS.ADD,
              payload: {
                id: ALERT_IDENTIFIERS.AUDIT_WARNING_ALERT,
                type: 'warning',
                message: 'Audit Logs',
                description: 'Generating logs. When completed, you will be notified to do the download.',
              }
            });
          } else {
            yield put({
              type: ALERTS.REMOVE,
              payload: ALERT_IDENTIFIERS.AUDIT_WARNING_ALERT
            });
          }

          if (errorLogs) {
            yield put({
              type: ALERTS.ADD,
              payload: {
                id: ALERT_IDENTIFIERS.AUDIT_ERROR_ALERT,
                type: 'error',
                message: 'Audit Logs',
                description: 'There was an error trying to get the Audit Logs..',
                buttons: [{
                  text: 'PROPERTIES LIST',
                  size: 'small',
                  type: 'link',
                  dispatchCustomAction: {
                    namespace: 'auditLogs',
                    action: 'toggleFailedModal'
                  },
                }],
                closable: true,
                autoClose: false
              }
            });
            return;
          } else {
            yield put({
              type: ALERTS.REMOVE,
              payload: ALERT_IDENTIFIERS.AUDIT_ERROR_ALERT
            });
          }
          if (successLogs) {
            yield put({
              type: ALERTS.ADD,
              payload: {
                id: ALERT_IDENTIFIERS.AUDIT_SUCCESS_ALERT,
                type: 'success',
                message: 'Audit Logs',
                description: 'Dear publisher, your initiated report is now complete. Please download this file before generating a new report. Note that generating a new report will result in over-riding this existing file - so please make sure the existing report is downloaded first.',
                buttons: [{
                  text: 'PROPERTIES LIST',
                  size: 'small',
                  type: 'link',
                  dispatchCustomAction: {
                    namespace: 'auditLogs',
                    action: 'toggleSuccessModal'
                  },
                }],
                closable: true,
                autoClose: false
              }
            });
          } else {
            yield put({
              type: ALERTS.REMOVE,
              payload: ALERT_IDENTIFIERS.AUDIT_SUCCESS_ALERT
            });
          }
        } else {
          yield put({
            type: ALERTS.REMOVE,
            payload: ALERT_IDENTIFIERS.AUDIT_ERROR_ALERT
          });
          yield put({
            type: ALERTS.REMOVE,
            payload: ALERT_IDENTIFIERS.AUDIT_SUCCESS_ALERT
          });
          yield put({
            type: ALERTS.REMOVE,
            payload: ALERT_IDENTIFIERS.AUDIT_WARNING_ALERT
          });
        }
        yield put({
          type: AUDIT_LOGS.STATUS.FULFILLED,
          payload: {
            data: res.data.map(log => ({ ...log, isDownloaded: savedLogs.some(sLogId => sLogId === log.id) })),
            pendingLogs: res.data.some(({ queryState }) => queryState === 'RUNNING' || queryState === 'QUEUED')
          }
        });
      } else {
        removeStorageLogs();
      }
    }
  } else {
    yield put({
      type: ALERTS.ADD,
      payload: {
        id: ALERT_IDENTIFIERS.AUDIT_ERROR_ALERT,
        type: 'error',
        message: 'There was an error trying to get the Audit Logs..',
        closable: true,
        autoClose: false
      }
    });
  }
}

export function* hideAuditModals({ payload }) {
  const { isErrorModal, logs } = payload;
  if (isErrorModal && logs) {
    const logsToUpdate = logs.map(log => log.id);
    updateStorageLogs(logsToUpdate);
    yield put({
      type: AUDIT_LOGS.STATUS.REMOVE_OLDER_LOGS,
      payload: logsToUpdate
    });
  }
}

export function* fetchPrepareDownload({ payload }) {
  yield put({ type: AUDIT_LOGS.PREPARE_DOWNLOAD.PENDING });
  if (payload.siteHasAnalytics) {
    const { res } = yield call(requestPrepareDownload, [
      payload.siteUrl,
      payload.pCode,
      payload.privacyMode,
      payload.subDomains,
      payload.propertyType,
      payload.startDate,
      payload.endDate
    ]);
    if (res) {
      yield put({
        type: ALERTS.ADD,
        payload: {
          id: ALERT_IDENTIFIERS.AUDIT_WARNING_ALERT,
          type: 'warning',
          message: 'Audit Logs',
          description: 'Generating logs. When completed, you will be notified to do the download.',
        }
      });
      yield fetchStatus({ payload });
    } else {
      yield put({
        type: ALERTS.ADD,
        payload: {
          type: 'error',
          message: 'Audit Logs',
          description: `Downloading logs. Error detected for: ${payload.siteUrl}.`,
          closable: true,
          autoClose: false
        }
      });
    }
  } else {
    yield put({
      type: ALERTS.ADD,
      payload: {
        id: ALERT_IDENTIFIERS.AUDIT_WARNING_ALERT,
        type: 'warning',
        message: 'Audit Logs',
        description: `Analytics not found for: ${payload.siteUrl}.`,
        closable: true,
        autoClose: false
      }
    });
  }
}

export function* fetchDownload({ payload }) {
  const { domain, uuid, logId, auditLogs } = payload;
  yield put({ type: AUDIT_LOGS.DOWNLOAD.PENDING, payload: { logId } });
  const { res, err } = yield call(requestDownload, [uuid, domain]);
  if (res) {
    if (res.data && res.data.USP) {
      downloadFile(res.data.USP, `${domain}(USP).xlsx`);
    }
    if (res.data && res.data.GDPR) {
      downloadFile(res.data.GDPR, `${domain}(GDPR).xlsx`);
    }
    yield put({ type: AUDIT_LOGS.DOWNLOAD.FULFILLED });
    updateStorageLogs([logId]);
    yield put({
      type: AUDIT_LOGS.STATUS.REMOVE_OLDER_LOGS,
      payload: [logId]
    });
    if (auditLogs.length - 1 === 0) {
      yield put({
        type: ALERTS.REMOVE,
        payload: ALERT_IDENTIFIERS.AUDIT_SUCCESS_ALERT
      });
    }
  } else {
    yield put({ type: AUDIT_LOGS.DOWNLOAD.REJECTED, payload: err.status });
  }
}

export function* fetchDownloadAll({ payload }) {
  yield put({ type: AUDIT_LOGS.DOWNLOAD_ALL.PENDING });
  const { auditLogs } = payload;
  const savedLogs = getStorageLogs();
  const filteredLogs = auditLogs.filter(log => !savedLogs.some(sLogId => sLogId === log.id));
  const errors = [];

  if (filteredLogs.length > 0) {
    const responses = yield filteredLogs.map(log => call(requestDownload, [log.uuid, log.url]));
    responses.forEach((response) => {
      const { res, url } = response;
      if (res.data) {
        if (res.data) {
          downloadFile(res.data.USP, `${url}(USP).zip`);
        }
        if (res.data && res.data.GDPR) {
          downloadFile(res.data.GDPR, `${url}(GDPR).zip`);
        }
      } else {
        errors.push(url);
      }
    });
  }

  if (errors.length > 0) {
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: 'Audit Logs',
        description: `Downloading logs. Error trying to download next files: ${errors.join(', ')}.`,
        closable: true,
        autoClose: false
      }
    });
  }
  const logsToUpdate = auditLogs.map(log => log.id);
  updateStorageLogs(logsToUpdate);
  yield put({
    type: AUDIT_LOGS.STATUS.REMOVE_OLDER_LOGS,
    payload: logsToUpdate
  });
  yield put({ type: AUDIT_LOGS.DOWNLOAD_ALL.FULFILLED });
  yield put({
    type: ALERTS.REMOVE,
    payload: ALERT_IDENTIFIERS.AUDIT_SUCCESS_ALERT
  });
}
