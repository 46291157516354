import {
  BRAZIL,
  CANADA,
  CHILD_PURPOSE_ID, EEA, GBC_CMP_VERSION, GDPR, GPP_CMP_VERSION, LATEST_CMP_VERSION, NEVER, OPT_OUT_PURPOSES,
  SENSITIVE_PURPOSE_ID, USA, USP, WORLDWIDE
} from './constants';
import TEXT from './text';
import SITETOOLTIPS from './toolTipsMessages';

export const isLatestCMP = cmpVersion => cmpVersion === 'latest' || cmpVersion === LATEST_CMP_VERSION;

export const isGBCSupported = cmpVersion => cmpVersion >= GBC_CMP_VERSION;

export const isGPPSupported = cmpVersion => cmpVersion >= GPP_CMP_VERSION;

export const getRegulationOptions = (consentLocations) => {
  const isDisabled = values => values.some(value => consentLocations.includes(value));

  return [
    {
      label: TEXT.GDPR_LABEL,
      value: GDPR,
      disabled: isDisabled([WORLDWIDE, EEA]),
      tooltip: SITETOOLTIPS.gdpr
    },
    {
      label: TEXT.USP_LABEL,
      value: USP,
      disabled: isDisabled([WORLDWIDE, USA]),
      tooltip: SITETOOLTIPS.US_regulation
    }
  ];
};

export const getConsentLocationOptions = (consentLocations) => {
  const isDisabled = values => values.some(value => consentLocations.includes(value));

  return [
    {
      value: EEA,
      label: TEXT.EEA_LABEL,
      disabled: isDisabled([WORLDWIDE, NEVER])
    },
    {
      value: WORLDWIDE,
      label: TEXT.WORLDWIDE_LABEL,
      disabled: isDisabled([EEA, USA, NEVER])
    },
    {
      value: USA,
      label: TEXT.USA_LABEL,
      disabled: isDisabled([WORLDWIDE, NEVER])
    },
    {
      value: NEVER,
      label: TEXT.NEVER_LABEL,
      disabled: isDisabled([WORLDWIDE, EEA, USA])
    },
    {
      value: BRAZIL,
      label: TEXT.BRAZIL,
      disabled: isDisabled([WORLDWIDE, NEVER])
    },
    {
      value: CANADA,
      label: TEXT.CANADA,
      disabled: isDisabled([WORLDWIDE, NEVER])
    }

  ];
};

const isValueDefined = value => typeof value !== 'undefined' && value !== null;

export const getConsentLocation = (consentLocations, regulation, requireConsentFrom) => {
  if (requireConsentFrom) {
    if (consentLocations && consentLocations.length > 0) {
      return consentLocations;
    }

    const locations = [];
    if (requireConsentFrom === 'always') {
      locations.push(WORLDWIDE);
      return locations;
    }
    if (requireConsentFrom === 'never') {
      locations.push(NEVER);
      return locations;
    }
    if (requireConsentFrom === 'inEU') {
      locations.push(EEA);
    }
    if (regulation.includes(USP)) {
      locations.push(USA);
    }
    return locations;
  }
  return [WORLDWIDE];
};

export const getMSPAInitData = (current) => {
  const {
    mspaOptOutPurposeIds, gdprEncodingMode, mspaJurisdiction, isCoveredTransaction,
    ccpaViaUsp, mspaSensitiveDataPurposeIds, mspaAutoPopUp, consentLocations, privacyModes, requireConsentFrom
  } = current;
  const currentMspaOptOutPurposeIds = mspaOptOutPurposeIds || [];
  const mspaPurposeIds = currentMspaOptOutPurposeIds.length > 0
    ? currentMspaOptOutPurposeIds.filter(id => id !== SENSITIVE_PURPOSE_ID && id !== CHILD_PURPOSE_ID)
    : OPT_OUT_PURPOSES;
  const mspaSensitiveDataProcessing = currentMspaOptOutPurposeIds.length > 0
    ? currentMspaOptOutPurposeIds.indexOf(SENSITIVE_PURPOSE_ID) !== -1
    : false;
  const mspaChildSensitiveDataProcessing = currentMspaOptOutPurposeIds.length > 0
    ? currentMspaOptOutPurposeIds.indexOf(CHILD_PURPOSE_ID) !== -1
    : false;


  const updatedConsentLocation = getConsentLocation(consentLocations, privacyModes, requireConsentFrom);

  return {
    gdprEncodingMode: gdprEncodingMode || 'TCF_AND_GPP',
    isCoveredTransaction: isValueDefined(isCoveredTransaction) ? isCoveredTransaction : false,
    mspaJurisdiction: mspaJurisdiction || 'STATE_AND_NATIONAL',
    ccpaViaUsp: isValueDefined(ccpaViaUsp) ? ccpaViaUsp : false,
    mspaPurposeIds,
    mspaSensitiveDataProcessing,
    mspaChildSensitiveDataProcessing,
    mspaSensitiveDataPurposeIds: mspaSensitiveDataPurposeIds || [],
    mspaAutoPopUp: isValueDefined(mspaAutoPopUp) ? mspaAutoPopUp : true,
    consentLocations: updatedConsentLocation
  };
};

export const getIsNonPersonalisedAdPresent = (vendorsData) => {
  if (!vendorsData || !vendorsData.data || !vendorsData.data.vendors) {
    return false;
  }
  const { blockedVendors, vendors } = vendorsData.data;
  const filterVendors = vendors.filter(vendor => !blockedVendors.includes(vendor.id));

  const nonPersonalisedVendors = [1, 2, 7, 8, 9, 10];
  const isNonPersonalisedAdPresent = filterVendors.some((vendor) => {
    const pruposes = vendor.purposes;
    return pruposes.some(purpose => nonPersonalisedVendors.includes(purpose));
  });
  return isNonPersonalisedAdPresent;
};
